/**
 * Finds all thumbnail containers on the page
 * @returns {NodeListOf<Element>}
 */
import {getParents} from "../../../common/js/Helpers";
import PhotoSwipeGallerySlide from "./PhotoSwipeGallerySlide";

/**
 *
 * @returns {Element[]}
 */
export function getThumbnailContainers() {
    let thumbEl = document.querySelectorAll( 'a[data-eg-lightbox]' );

    // don't add images from the clone slides. They are not clickable but will add duplicates to the lightbox => #818
    return Array.from(thumbEl).filter((tc => {
        let parents = getParents(tc);
        let thumbElIsAllowed = true;
        parents.forEach(parent => {
            if (thumbElIsAllowed && parent.classList.contains('glide__slide--clone')) {
                thumbElIsAllowed = false;
            }
        });
        return thumbElIsAllowed;
    }))
}

/**
 *
 * @param el
 * @param fn
 * @returns {Element}
 */
export function  closest(el, fn) {
    return el && ( fn(el) ? el : closest(el.parentNode, fn) );
}

/**
 *
 * @param thumbEl Node
 * @returns PhotoSwipeGallerySlide
 */
export function  parseThumbnailElement(thumbEl) {
    let item,
        imgEl,
        height,
        width,
        pid,
        gid;

    // include only element nodes
    if(thumbEl.nodeType !== 1) {
        return null;
    }

    imgEl = thumbEl.getElementsByTagName('IMG')[0];

    if (imgEl === undefined) {
        return null;
    }

    height = thumbEl.getAttribute('data-height');
    width = thumbEl.getAttribute('data-width');
    pid = thumbEl.getAttribute('data-pid');
    gid = thumbEl.getAttribute('data-gid');

    if (height === null || width === null || pid === null) {
        return null;
    }

    // create slide object
    item = new PhotoSwipeGallerySlide();
    item.type = thumbEl.getAttribute('data-type')

    if (item.type === 'video' && thumbEl) {
        if (thumbEl.dataset.pswpVideoSources) {
            item.videoSources = JSON.parse(pswpVideoSources);
        } else if (thumbEl.dataset.pswpVideoSrc) {
            item.videoSrc = thumbEl.dataset.pswpVideoSrc;
        } else {
            item.videoSrc = thumbEl.href;
        }
    }

    item.src = thumbEl.getAttribute('href');
    item.width = parseInt(width, 10);
    item.height = parseInt(height, 10);

    // prevent null entry
    item.title = decodeURIComponent(thumbEl.getAttribute('data-bs-title') || thumbEl.getAttribute('data-title') || '');

    item.pid = parseInt(pid, 10);

    item.gid = gid;

    item.thumbEl  = thumbEl; // save link to element for getThumbBoundsFn


    let shareButton = thumbEl.querySelector('.social-share-button');
    if (shareButton) {
        item.shareUrl = shareButton.getAttribute('data-href');
    }

    item.directDownloadButton = thumbEl.querySelector('.eg-download');

    let add2cartButton = thumbEl.querySelector('.eventgallery-add2cart');
    if (add2cartButton) {
        item.add2cartUrl = window.EventGalleryCartConfiguration?.add2cartdialogurl + '&' + add2cartButton.getAttribute('data-id');
    }

    return item;
}





