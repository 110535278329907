import CamelCaseConverter from "../utils/camelCaseConverter";

const prefix = "data-slider-";

export default class SliderOptions {

    constructor(element) {
        this.element = element;
        this.options = Object.assign({
            autoplay: false,
            slidesElementsSelector: '',
            numberOfRowsPerSlide: 1,
            showNav: 'true',
        }, SliderOptions._parseOptions(element));
    }


    /**
     *
     * @param element HTMLElement
     * @private
     */
    static _parseOptions(element) {
        let options = {};

        let attributes = element.attributes;
        for (let i =0; i< attributes.length; i++) {
            if (attributes[i].name.indexOf(prefix) === 0) {
                options[CamelCaseConverter.toCamelCase(attributes[i].name.replace(prefix,''))] = attributes[i].value;
            }
        }

        return options;
    }

    getAutoPlay() {

        if (this.options.autoplay === false) {
            return false;
        }
        return parseInt(this.options.autoplay);
    }

    getSlidesElementsSelector() {
        return this.options.slidesElementsSelector;
    }

    doShowNav() {
        return this.options.showNav === 'true';
    }

    getNumberOfRowsPerSlide() {
        return parseInt(this.options.numberOfRowsPerSlide);
    }
}
