export default class URLHelper {

    constructor() {
    }

    /**
     * splits a URL parameter like foo=bar&1=2 into a parameter map
     *
     * @param urlParameterString
     * @returns {Map<any, any>}
     */
    static parseURLParameter(urlParameterString) {
        let pairs = urlParameterString.split('&');
        let parameters = new Map();

        pairs.forEach(pair => {
            let splittedPair = pair.split('=');
            if (splittedPair.length === 2) {
                parameters.set(splittedPair[0], splittedPair[1]);
            }
        });

        return parameters;
    }

    /**
     * returns a map of the query string of an full url
     *
     * @param urlSring
     * @returns {URLSearchParams}
     */
    static getParameters(urlSring) {
        let parts = urlSring.split('?');
        if (parts.length < 2) {
            return new Map();
        }
        let queryParts = parts[1].split('#');
        return URLHelper.parseURLParameter(queryParts[0]);
    }

    /**
     * returns the url from strings like "url ('foobar.jpg')"
     *
     * @param urlString
     * @returns {*}
     */
    static extractBackgroudUrl(urlString) {
        return urlString.replace(/(url\(|\)|"|')/g, '');
    }
};


