import {forEach, getElementWidth, mergeObjects} from "../../common/js/Helpers";
import Image from "./EventgalleryImage";
import Row from "./EventgalleryRow";

export default class Imagelist {

    constructor(newOptions) {

        this.options = {
            rowHeightPercentage: 100,
            rowHeight: 150,
            rowHeightJitter: 0,
            minImageWidth: 150,
            // resize the last image to full width
            doFillLastRow: false,
            // the object where we try to get the width from
            imagesetContainer: null,
            // the object containing all the images elements. Usually they are retieved with a selector like '.imagelist a',
            imageset: null,
            firstImageRowHeight: 2,
            initComplete: function () {
            },
            resizeStart: function () {
            },
            resizeComplete: function () {
            }
        };
        this.images = [];
        // used to compare for width changes
        this.eventgalleryPageWidth = 0;
        // the width of the container. This is kind of tricky since there can be many containers or just one.
        this.width = null;
        this.options = mergeObjects(this.options, newOptions);
        if (undefined !== newOptions) {
            this.initialize();
        }
    };

    initialize() {

        this.width = getElementWidth(this.options.imagesetContainer);

        // save the current width so we don't react on an resize event if not necessary
        this.eventgalleryPageWidth = this.width;

        this.images = [];

        forEach(this.options.imageset, (item, index) => {
            this.images.push(new Image(item, index));
        })

        window.addEventListener('resize', () => {
            window.clearTimeout(this.eventgalleryTimer);

            this.eventgalleryTimer = setTimeout(() => {
                let new_width = getElementWidth(this.options.imagesetContainer);
                this.width = new_width;
                if (this.eventgalleryPageWidth !== new_width) {
                    this.options.resizeStart();
                    this.eventgalleryPageWidth = new_width;

                    this.options.imagesetContainer.style.minHeight = (this.options.rowHeight * this.images.length) + 'px';

                    this.processList();

                    this.options.imagesetContainer.style.minHeight = '0px';

                    this.options.resizeComplete();
                }
            }, 500);

        });

        if (this.options.imagesetContainer) {
            this.options.imagesetContainer.style.minHeight = (this.options.rowHeight * this.images.length) + 'px';

            this.processList();

            this.options.imagesetContainer.style.minHeight = '0px';
        }

        //add a tiny timeout. This prevents some issue with lazyload
        //where images didn't load since the offset was wrong.
        window.setTimeout(this.options.initComplete, 1);
    };

    /*calculated the with of an element*/
    getRowWidth() {
        var rowWidth = this.width;

        /* fix for the internet explorer if width if 45.666% == 699.87px*/
        if (window.getComputedStyle) {
            let computedStyle = window.getComputedStyle(this.options.imagesetContainer)
            if (computedStyle.boxSizing === 'border-box') {
                let paddingLeft = parseFloat(computedStyle.paddingLeft)
                let paddingRight = parseFloat(computedStyle.paddingRight)
                let borderLeft = parseFloat(computedStyle.borderLeftWidth)
                let borderRight = parseFloat(computedStyle.borderRightWidth)
                rowWidth = Math.floor(parseFloat(computedStyle.width) - paddingLeft - paddingRight - borderLeft - borderRight) - 1;
            } else {
                rowWidth = Math.floor(parseFloat(computedStyle.width)) - 1;
            }
        } else {
            rowWidth = rowWidth - 1;
        }

        return rowWidth;
    };

    /* processes the image list*/
    processList() {
        let options;

        /* find out how much space we have*/
        let rowWidth = this.getRowWidth();


        /* get a copy of the image list because we will pop the image during iteration*/
        let imagesToProcess = this.images.slice(0);

        if (imagesToProcess.length === 0) {
            return;
        }

        /* display the first image larger */
        if (this.options.firstImageRowHeight > 1) {
            let image = imagesToProcess.shift();

            /*if we have a large image, we have to hide it to get the real available space*/
            image.hide();
            rowWidth = this.getRowWidth();
            image.show();

            let imageHeight = this.options.firstImageRowHeight * this.options.rowHeight;
            let imageWidth = Math.floor(image.width / image.height * imageHeight);

            if (imageWidth + this.options.minImageWidth >= rowWidth) {
                imageWidth = rowWidth;
            }

            image.setSize(imageWidth, imageHeight);

            let rowHeightForRightSideImages = this.options.rowHeight;

            // in case the browser zooms to 110%, we need to make sure, the first image is smaller thand
            // the images to the right. We do this by checking if there is a height difference between the
            // height we want to get and the actual height. If there is one, we add a pixel to the rows
            // on the right side.

            // even if the left side is smaller than the right side, the browsers still have an issue with floating.
            // Example: left: 499.98px, right: 249,98px*2=499.96 => issue! So we make the left side a tiny bit bigger.
            rowHeightForRightSideImages = (imageHeight + 0.05) / this.options.firstImageRowHeight;
            if (imageHeight > image.getImageTagHeight()) {
                rowHeightForRightSideImages = (imageHeight + 1) / this.options.firstImageRowHeight;
            }

            options = {
                maxWidth: rowWidth - imageWidth,
                maxHeight: rowHeightForRightSideImages,
                adjustHeight: false
            };

            if (options.maxWidth > 0) {
                this.generateRows(imagesToProcess, this.options.firstImageRowHeight, options, false);
            }
        }

        options = {
            maxWidth: rowWidth,
            maxHeight: this.options.rowHeight,
            heightJitter: this.options.rowHeightJitter,
            doFillLastRow: this.options.doFillLastRow
        };

        this.generateRows(imagesToProcess, 99999, options, true);

    };

    /**
     * @param imagesToProcess
     * @param numberOfRowsToCreate
     * @param options
     * @param finalRows
     */
     generateRows(imagesToProcess, numberOfRowsToCreate, options, finalRows) {
        let currentRow = new Row(options);

        while (imagesToProcess.length > 0 && numberOfRowsToCreate > 0) {
            let addSuccessfull = currentRow.add(imagesToProcess[0]);
            if (addSuccessfull) {
                imagesToProcess.shift();
            } else {
                currentRow.processRow();
                numberOfRowsToCreate--;
                if (numberOfRowsToCreate === 0) break;
                currentRow = new Row(options);
            }
        }

        if (finalRows) {
            currentRow.isLastRow = true;
        }
        currentRow.processRow();
    };

}
