import {ClickableButton, DownloadButton} from "./socialbutton";
import Overlay from "../Overlay";

document.addEventListener('DOMContentLoaded', () => {

    let elements = document.querySelectorAll('.social-share-button-open');
    for (let i=0; i<elements.length;i++) {
        let href = elements[i].getAttribute('data-href');
        elements[i].addEventListener("click", (e) => {
            e.preventDefault();
            let overlay = new Overlay();
            overlay.openOverlay(href);
        }, true);
    }

    elements = document.querySelectorAll('.eg-download');
    for (let i=0; i<elements.length;i++) {
        let element = elements[i];
        let href = element.getAttribute('data-href');
        let download = element.getAttribute('data-download');

        if (download != null) {
            element.addEventListener("click", (e) => {
                DownloadButton.download(e, href, download);
            }, true);
        } else {
            element.addEventListener("click", (e) => {
                ClickableButton.click(e, href);
            }, true);
        }
    }

});
