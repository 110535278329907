import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';
import PhotoSwipeVideoPlugin from '../photoswipe-video-plugin/index';
import {getElementHeight, getParent, mergeObjects, removeElement} from "../../../common/js/Helpers";
import Polyfill from "../Polyfill";
import Overlay from "../Overlay";
import SlideShow from "../SlideShow";
import {parseHash} from "./UrlHandling";
import {closest, getThumbnailContainers, parseThumbnailElement} from "./SlideGenerator";
import {Events} from "../minicart/CartEvents";
import HistoryHandler from "./HistoryHandler";



export default class PhotoSwipeGallery {

    constructor(options) {
        this._isOpen = false;
        this._gallery = null;
        this._videoPlugin = null;
        this.options = mergeObjects({
            doOverlapToolbar: false,
            imagePadding: 0,
            doOverlapImageWithCaption: false,
            showHideAnimationType: 'zoom',
        }, options);
    }

    initPhotoSwipe() {
        this.translations = this._translate();
        this._registerClickEvents();

        // Parse URL and open gallery if it contains #&pid=3&gid=1
        let hashData = parseHash();
        if(hashData.pid && hashData.gid) {
            this.openPhotoSwipe( hashData.pid ,  this.getGalleries().get(hashData.gid), true, true );
        }
    };

    _translate() {
        if (!window.EventGalleryLightboxConfiguration) {
            return {};
        }

        return {
            closeTitle: EventGalleryLightboxConfiguration.KEY_CLOSE,
            zoomTitle: EventGalleryLightboxConfiguration.KEY_ZOOM,
            arrowPrevTitle: EventGalleryLightboxConfiguration.KEY_PREVIOUS,
            arrowNextTitle: EventGalleryLightboxConfiguration.KEY_NEXT,
            errorMsg: 'The image cannot be loaded',
            indexIndicatorSep: ' / ',
            share: EventGalleryLightboxConfiguration.KEY_SHARE,
            download: EventGalleryLightboxConfiguration.KEY_DOWNLOAD,
            buy: EventGalleryLightboxConfiguration.KEY_BUY,
            'playpause-buttonTitle': EventGalleryLightboxConfiguration.KEY_PLAYSLIDESHOW,
            pause: EventGalleryLightboxConfiguration.KEY_PAUSESLIDESHOW,
        }
    }

    onThumbnailsClick(e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        let eTarget = e.target || e.srcElement;

        // leave the icons for cart&sharing alone
        let iconContainer = closest(eTarget, function(el) {
            return (el.classList && el.classList.contains('eventgallery-icon-container'));
        });
        if (iconContainer !== null) {
            return;
        }

        // find root element of slide
        let clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'A');
        });

        if(!clickedListItem) {
            return;
        }

        let slide = this.getSlideBy(clickedListItem);
        let index = this.getIndexOfSlide(slide);
        let slides = this.getGalleries().get(slide.gid);

        if(index >= 0) {
            // open PhotoSwipe if valid index found
            this.openPhotoSwipe( index, slides );
        }
        return false;
    };

    /**
     *
     * @param index
     * @param slides
     * @param disableAnimation
     * @param fromURL
     */
    openPhotoSwipe(index, slides, disableAnimation, fromURL, useHistory = true) {
        if (slides === undefined || slides.length === 0 ) {
            return;
        }

        // define options (if needed)
        let options = mergeObjects({
            pswpModule: PhotoSwipe,

            galleryPIDs:true,
            bgOpacity: 1,
            trapFocus: false,
            showHideAnimationType: this.options.showHideAnimationType,

            // define gallery index (for URL)
            galleryUID: slides[0].gid,

            'tapAction': () => {
                this._gallery.element.classList.toggle('pswp--ui-visible');
                if (this._gallery.element.classList.contains('pswp--ui-visible')) {
                    this._gallery.element.classList.remove('pswp--ui-invisible');
                } else {
                    this._gallery.element.classList.add('pswp--ui-invisible');
                }

                this._gallery.updateSize(true);
            },


            paddingFn: (viewportSize, itemData, index) => {

                let top = 0;
                let bottom = 0;
                let captionHeight = 0;

                if (!this._gallery.element.classList.contains('pswp--ui-invisible')) {

                    if (this.options.doOverlapToolbar === false) {
                        top = getElementHeight(document.querySelector('.pswp__top-bar'))
                    }

                    top += this.options.imagePadding;

                    if (!this.options.doOverlapImageWithCaption) {
                        if (itemData.title) {
                            const el = document.createElement('div');
                            el.className = 'pswp__custom-caption';
                            el.innerHTML = itemData.title;
                            this._gallery.element.appendChild(el);
                            captionHeight = getElementHeight(el);
                            let cSSStyleDeclaration = getComputedStyle(el);

                            if (cSSStyleDeclaration['bottom']) {
                                let captionBottom = parseInt(cSSStyleDeclaration['bottom'], 10)
                                captionHeight += captionBottom;
                                captionHeight += Math.max(0, 4 - this.options.imagePadding);
                            }

                            Polyfill.removeHtmlElementNode(el);
                        }
                    }
                }

                return {
                    top,
                    bottom: bottom +  captionHeight,
                    left: 0,
                    right: 0
                };
            },
        }, this.translations);

        // disable close gestures to avoid trouble with the add2cart dialog.
        options.pinchToClose = !options.add2cartEl;
        options.closeOnVerticalDrag = !options.add2cartEl;
        options.closeOnScroll = !options.add2cartEl;

        if (window.EventGalleryLightboxConfiguration) {
            if (parseInt(window.EventGalleryLightboxConfiguration.navigationFadeDelay) > 0) {
                options.timeToIdle = parseInt(window.EventGalleryLightboxConfiguration.navigationFadeDelay);
                options.timeToIdleOutside = parseInt(window.EventGalleryLightboxConfiguration.navigationFadeDelay);
            }
        }

        // PhotoSwipe opened from URL
        if(fromURL) {
            if(options.galleryPIDs) {
                index = parseInt(index, 10);
                // parse real index when custom PIDs are used
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for(let j = 0; j < slides.length; j++) {
                    if(slides[j].pid === index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if( isNaN(options.index) ) {
            return;
        }

        if(disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it

        options.dataSource = slides;


        this._gallery = new PhotoSwipe(options);


        /** allows the zoom closing */
        this._gallery.addFilter('thumbEl', (thumbEl, data, index) => {
            if (data.thumbEl) {
                const el = data.thumbEl.querySelector('img');
                if (el) {
                    return el;
                }
            }
            return thumbEl;
        });

        /** get a placeholder */
        /*this._gallery.addFilter('placeholderSrc', (placeholderSrc, slide) => {
            const el = slide.data.thumbEl.querySelector('img');
            if (el) {
                return el.src;
            }
            return placeholderSrc;
        });*/

        this._gallery.on('close', () => {
            return this._isOpen = false;
        });

        this._gallery.on('change', () => {
            this.overlay?.closeOverlay();
        })

        // HISTORY

        let historyHandler = new HistoryHandler({
            onClose: () => this._gallery.close(),
            history: useHistory ? history : null
        })

        this._gallery.on('afterInit', () => {
            historyHandler.startUrlHistory();
            historyHandler.adjustUrlHistory(this.getCurrentSlide());
        });

        this._gallery.on('change', () => {
            historyHandler.adjustUrlHistory(this.getCurrentSlide());
        })

        this._gallery.on('close', () => {
            historyHandler.endUrlHistory();
        })
        // END HISTORY

        this._gallery.on('keydown', (e) => {
            // prevent photoswipe to react on keypresses
            if (this.overlay?.isOpen) {
                e.defaultPrevented = true;
            }
        })

        this._gallery.on('close', () => {
            this.overlay?.closeOverlay();
        })

        this._gallery.on('change', () => {
            document.dispatchEvent(Polyfill.createNewEvent('eventgallery-lightbox-changed'));
        });

        this._registerUIElements();

        this._videoPlugin = new PhotoSwipeVideoPlugin(this._gallery, {
            // options
        });

        this._gallery.init();

        this._isOpen = true;

        document.dispatchEvent(Polyfill.createNewEvent('eventgallery-lightbox-opened'));

    };

    _registerUIElements() {
        this._gallery.on('uiRegister', () => {

            this._gallery.ui.registerElement({
                name: 'custom-caption',
                order: 9,
                isButton: false,
                appendTo: 'root',
                html: 'Caption text',
                onInit: (el, pswp) => {
                    pswp.on('change', () => {
                        const currSlideElement = this.getCurrentSlide();
                        let captionHTML = '';
                        if (currSlideElement) {
                            captionHTML = currSlideElement.title;
                        }

                        el.innerHTML = captionHTML || '';
                        if (captionHTML.length > 0 ) {
                            el.style.display = 'block';
                        } else {
                            el.style.display = 'none';
                        }

                    });
                }
            });

            this._gallery.ui.registerElement({
                name: 'share-button',
                ariaLabel: this.translations.share,
                title: this.translations.share,
                order: 10,
                isButton: true,
                html: '',
                onInit: (el, pswp) => {
                    pswp.on('change', (a,) => {
                        if (this.getCurrentSlide().shareUrl) {
                            el.style.display = 'block';
                        } else {
                            el.style.display = 'none';
                        }
                    });
                },
                onClick: (event, el, pswp) => {
                    this.overlay = new Overlay();
                    let url = this.getCurrentSlide().shareUrl
                    this.overlay.openOverlay(url);
                    return false;
                }
            });

            this._gallery.ui.registerElement({
                name: 'add2cart-button',
                ariaLabel: this.translations.buy,
                title: this.translations.buy,
                order: 11,
                isButton: true,
                html: '',
                onInit: (el, pswp) => {
                    pswp.on('change', (a,) => {
                        if (this.getCurrentSlide().add2cartUrl) {
                            el.style.display = 'block';
                        } else {
                            el.style.display = 'none';
                        }

                    });
                },
                onClick: (event, el, pswp) => {
                    this.overlay = new Overlay();

                    this.overlay.onLoad = () => {
                        document.dispatchEvent(Polyfill.createNewEvent(Events.prepareAdd2cartDialogContent, null));
                    };

                    let url = this.getCurrentSlide().add2cartUrl;
                    this.overlay.openOverlay(url);

                    return false;
                }

            });

            this._gallery.ui.registerElement({
                name: 'download-button',
                ariaLabel: this.translations.download,
                title: this.translations.download,
                order: 12,
                isButton: true,
                html: '',
                onInit: (el, pswp) => {
                    pswp.on('change', (a,) => {
                        if (this.getCurrentSlide().directDownloadButton) {
                            el.style.display = 'block';
                        } else {
                            el.style.display = 'none';
                        }

                    });
                },
                onClick: (event, el) => {
                    this.getCurrentSlide().directDownloadButton?.click();
                    return false;
                }
            });

            new SlideShow(this._gallery);
        })


    }

    _registerClickEvents() {
        // loop through all gallery elements and bind events
        let galleries = this.getGalleries();

        for(let gallery of galleries.values()) {
            for (let i = 0, l = gallery.length; i < l; i++) {
                gallery[i].thumbEl.onclick = (e) => {this.onThumbnailsClick(e)};
            }
        }
    }

    /**
     * searches the galleries for a thumbnail container and returns the
     * Slide object
     *
     * @param thumbEl
     * @returns PhotoSwipeGallerySlide
     */
    getSlideBy(thumbEl) {
        let galleries = this.getGalleries();

        for(let gallery of galleries.values()) {
            for (let i = 0, l = gallery.length; i < l; i++) {
                if (gallery[i].thumbEl === thumbEl) {
                    return gallery[i];
                }
            }
        }

        return null;
    }


    /**
     *
     * @param slide PhotoSwipeGallerySlide
     * @returns {*|number}
     */
    getIndexOfSlide(slide) {
        let slides = this.getGalleries().get(slide.gid);
        return slides.map(function(e) { return e.hash(); }).indexOf(slide.hash());
    }

    /**
     * Parses all elements on the page so we know which triggers for a lightbox exist.
     * Returns a mal containing the gallery and all elements
     * @returns {Map<String, Array>}
     */
    getGalleries() {
        let galleries = new Map(),
            thumbnails = getThumbnailContainers();

        for(let i=0; i<thumbnails.length; i++) {
            let thumbEl = thumbnails[i];
            let gallery;

            let item = parseThumbnailElement(thumbEl);
            if (item !== null) {
                if (galleries.get(item.gid) === undefined) {
                    galleries.set(item.gid, []);
                }
                gallery = galleries.get(item.gid);
                gallery.push(item);
                galleries.set(item.gid, gallery);
            }
        }

        return galleries;
    }

    /**
     * grabs the current slide from the lightbox
     * @returns PhotoSwipeGallerySlide
     */
    getCurrentSlide() {
        return this._gallery.currSlide.data;
    }

    /**
     * Returns the index if the current item in the lightbox
     *
     * @returns integer
     */
    getCurrentIndex() {
        return this._gallery.currIndex;
    }

    /**
     * reinitiate the lightbox. Register the click events for existing and new elements.
     */
    reload() {
        this._registerClickEvents();
    }

    /**
     * reports back if the lightbox is currently open
     * @returns {boolean}
     */
    isOpen() {
        return this._isOpen;
    }

    /**
     *
     * @param afterChangeFunction
     */
    setAfterChangeEventListener(afterChangeFunction) {
        this._gallery.on('change', afterChangeFunction);
    }

    /**
     * jump to a specific slide.
     * @param index
     */
    gotoSlide(index) {
        this._gallery.goTo(index);
    }

}

