
/* determines the size of an image so a image server can deliver it. */

import {mergeObjects} from "../../common/js/Helpers";

export default class SizeCalculator {
    constructor(newOptions) {
        this.options = {
            // to be able to handle internal and google photos images, we need to restrict the availabe image sizes.
            availableSizes: [48, 104, 160, 288, 320, 400, 512, 640, 720, 800, 1024, 1280, 1440],
            flickrSizes:   {100 : 't', 240 : 'm', 320 : 'n', 500 : '-', 640 : 'z', 800 : 'c', 1024 : 'b', 1600 : 'h', 2048 : 'k'}

        };
        this.options = mergeObjects(this.options, newOptions);
    }

    adjustImageURL(url, size) {

        // only needed for Google Photos
        url = url.replace(/=w(\d+)$/, '=w' + size);
        url = url.replace(/=w(\d+)-nu$/, '=w' + size + '-nu');

        // neded for S3 image structure
        url = url.replace(/\/s(\d*)\//, '/s' + size + '/');
        url = url.replace(/\/s(\d*)-c\//, '/s' + size + '-c/');

        // used by Event Gallery
        url = url.replace(/width=(\d+)/, 'width=' + size);
        url = url.replace(/\/mainimage_nocrop_(\d+)_/, '/mainimage_nocrop_' + size + '_');
        url = url.replace(/\/nocrop_(\d+)_/, '/nocrop_' + size + '_');

        return url;
    };


    getFlickrURL(farm, server, secret,  secret_h, secret_k, secret_o, id, width, height, originalwidth, originalheight) {
        let longSideSize,
            originalLongSideSize,
            sizeCode,
            secretString,
            sizeString;

        let minSizes = this.getMinSizes(width, height, originalwidth, originalheight);


        if (minSizes.width > minSizes.height) {
            longSideSize = minSizes.width;
            originalLongSideSize = originalwidth;
        } else {
            longSideSize = minSizes.height;
            originalLongSideSize = originalheight;
        }

        sizeCode = 'o';

        if (originalLongSideSize >= longSideSize) {
            for (let size in this.options.flickrSizes) {
                if (size > longSideSize) {
                    sizeCode = this.options.flickrSizes[size];
                    break;
                }
            }
        }

        if (sizeCode === 'o' && secret_o === '') {
            sizeCode = 'k'
        }

        if (sizeCode === 'k' && secret_k === '') {
            sizeCode = 'h'
        }

        if (sizeCode === 'h' && secret_h === '') {
            sizeCode = 'b'
        }

        switch (sizeCode) {
            case "o":
                secretString = secret_o;
                break;
            case "h":
                secretString = secret_h;
                break;
            case "k":
                secretString = secret_k;
                break;
            default:
                secretString = secret;
        }

        sizeString = sizeCode == '-' ? '' : '_' + sizeCode;

        return 'https://live.staticflickr.com/' + server + '/' + id + '_' + secretString + sizeString + '.jpg';
    };

    getSize(width, height, ratio) {

        let googleWidth = this.options.availableSizes[0];

        for(let index=0; index < this.options.availableSizes.length; index++) {
            let item = 	this.options.availableSizes[index];
            let widthOkay;
            let heightOkay;

            if (googleWidth > this.options.availableSizes[0]){
                break;
            }

            let lastItem = index == this.options.availableSizes.length - 1;

            if (ratio >= 1) {
                widthOkay = item > width;
                heightOkay = item / ratio > height;

                if ((widthOkay && heightOkay) || lastItem) {
                    googleWidth = item;
                }
            } else {
                heightOkay = item > height;
                widthOkay = item * ratio > width;

                if ((widthOkay && heightOkay) || lastItem) {
                    googleWidth = item;
                }
            }
        }

        return googleWidth;
    };

    /**
     * returns the minimum values for height and width to fill the given box size.
     * @param boxWidth
     * @param boxHeight
     * @param originalWidth
     * @param originalHeight
     */
    getMinSizes(boxWidth, boxHeight, originalWidth, originalHeight) {


          let ratio = originalWidth / originalHeight;

          let height, width;

          if (originalWidth > originalHeight) {
              if (boxWidth > boxHeight) {
                  width = boxWidth;
                  height = boxWidth / ratio;
                  if (height < boxHeight) {
                      height = boxHeight;
                      width = boxHeight * ratio;
                  }
              } else {
                  height = boxHeight;
                  width = boxHeight * ratio;
                  if (width < boxWidth) {
                      width = boxWidth;
                      height = boxHeight * ratio;
                  }
              }
          } else {
              if (boxWidth > boxHeight) {
                  width = boxWidth;
                  height = boxWidth / ratio;
                  if (height < boxHeight) {
                      height = boxHeight;
                      width = boxHeight * ratio;
                  }
              } else {
                  height = boxHeight;
                  width = boxHeight * ratio;
                  if (width < boxWidth) {
                      width = boxWidth;
                      height = boxWidth / ratio;
                  }
              }
          }

          return {width: Math.ceil(width), height: Math.ceil(height)};
    };
}
