import EventsHandler from "../utils/eventshandler";

export default function (Glide, Components, Events) {
    /**
     * Instance of the binder for DOM Events.
     *
     * @type {EventsHandler}
     */
    const Binder = new EventsHandler();

    /**
     * Holds preventing status of anchors.
     * If `true` redirection after click will be disabled.
     *
     * @private
     * @type {Boolean}
     */
    let prevented = false;

    let isSwiping = false;

    const Anchors = {
        /**
         * Setups a initial state of anchors component.
         *
         */
        mount () {
            /**
             * Holds collection of anchors elements.
             *
             * @private
             * @type {HTMLCollection}
             */
            this._a = Components.Html.wrapper.querySelectorAll('a,span,img');

            this.bind();
            isSwiping = false;
        },

        /**
         * Binds events to anchors inside a track.
         *
         */
        bind () {
            Binder.on('click', Components.Html.wrapper, this.click, true)
        },

        /**
         * Unbinds events attached to anchors inside a track.
         *
         */
        unbind () {
            Binder.off('click', Components.Html.wrapper)
        },

        /**
         * Handler for click event. Prevents clicks when glide is in `prevent` status.
         *
         * @param  {Object} event
         */
        click (event) {
            if (prevented) {
                event.stopPropagation();
                event.preventDefault()
            }
        },

        /**
         * Detaches anchors click event inside glide.
         *
         * @return {self}
         */
        detach () {
            prevented = true;

            return this
        },

        /**
         * Attaches anchors click events inside glide.
         *
         * @return {self}
         */
        attach () {
            prevented = false;

            return this
        }
    };

    Object.defineProperty(Anchors, 'items', {
        /**
         * Gets collection of the arrows HTML elements.
         *
         * @return {HTMLElement[]}
         */
        get () {
            return Anchors._a
        }
    });

    /**
     * Detach anchors inside slides:
     * - on swiping, so they won't redirect to its `href` attributes
     */
    Events.on('swipe.move', () => {
        Anchors.detach();
    });

    /**
     * Attach anchors inside slides:
     * - after swiping and transitions ends, so they can redirect after click again
     */
    Events.on('swipe.end', () => {
        Components.Transition.after(() => {
            Anchors.attach()
        });
    });

    /**
     * Unbind anchors inside slides:
     * - on destroying, to bring anchors to its initial state
     */
    Events.on('destroy', () => {
        Anchors.attach();
        Anchors.unbind();
        Binder.destroy()
    });

    return Anchors
}
