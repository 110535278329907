/*
     Class to manage an image. This can be the img tag or a container. It has to manage glue itself.
     */

import {calcBorderWidth, getElementHeight, getElementWidth, mergeObjects} from "../../common/js/Helpers";
import SizeCalculator from "./EventgallerySizeCalculator";

export default class Image{
    constructor(image, index, newOptions) {
        this.options = mergeObjects(this.options, newOptions);
        /**
         * HTMLElement
         */
        this.imageTagContainer = image;
        this.index = index;
        this.calculatedWidth = 0;
        this.calcSize()
    }

    calcSize() {
        // glue includes everything but the image width/heigt: margin, padding, border
        let image = this.imageTagContainer.querySelector('img');

        if (!image) {
            return;
        }

        let elements = [this.imageTagContainer, image];

        this.glueLeft = calcBorderWidth(elements, ['paddingLeft', 'marginLeft', 'borderLeftWidth']);
        this.glueRight = calcBorderWidth(elements, ['paddingRight', 'marginRight', 'borderRightWidth']);
        this.glueTop = calcBorderWidth(elements, ['paddingTop', 'marginTop', 'borderTopWidth']);
        this.glueBottom = calcBorderWidth(elements, ['paddingBottom', 'marginBottom', 'borderBottomWidth']);

        // get image size from data- attributes

        this.width = parseInt(image.getAttribute("data-width"));
        this.height = parseInt(image.getAttribute("data-height"));

        // fallback of data- attributes are not there
        if (this.width === undefined) {
            this.width = getElementWidth(this.imageTagContainer) - this.glueLeft - this.glueRight;
        }

        if (this.height === undefined) {
            this.height = getElementHeight(this.imageTagContainer) - this.glueTop - this.glueBottom;
        }
    };

    /**
     * calculates the height of that image container. This includes the image and the gap on top/bottom
     *
     * @returns number
     */
    getImageTagHeight() {
        return parseFloat(window.getComputedStyle(this.imageTagContainer).height) + this.glueTop + this.glueBottom;
    };

    setSize(width, height) {
        this.calcSize()

        let isFlickr = false;
        let newWidth = width - this.glueLeft - this.glueRight;
        let newHeight = height - this.glueTop - this.glueBottom;

        let ratio = this.width / this.height;

        //console.log("the size of the image should be: "+width+"x"+height+" so I have to set it to: "+newWidth+"x"+newHeight);
        //adjust background images
        let image = this.imageTagContainer.querySelector('img');
        if (!image) {
            return;
        }

        let sizeCalculator = new SizeCalculator();

        let dataSrc = image.getAttribute('data-src');
        if (!dataSrc) {
            dataSrc = "";
        }

        let secret = image.getAttribute('data-secret');

        if (secret !== null) {
            let secret_o = image.getAttribute('data-secret_o');
            let secret_h = image.getAttribute('data-secret_h');
            let secret_k = image.getAttribute('data-secret_k');
            let farm = image.getAttribute('data-farm');
            let type = image.getAttribute('data-type')
            let server = image.getAttribute('data-server');
            let id = image.getAttribute('data-id');
            let imageUrl = sizeCalculator.getFlickrURL(farm, server, secret, secret_h, secret_k, secret_o, id, newWidth, newHeight, this.width, this.height);

            dataSrc = imageUrl;

            isFlickr = true;

        } else {
            let googleWidth = sizeCalculator.getSize(newWidth, newHeight, ratio);
            dataSrc = sizeCalculator.adjustImageURL(dataSrc, googleWidth);
        }

        image.setAttribute('data-src', dataSrc);
        image.setAttribute('srcset', dataSrc);
        image.style.display = 'block';
        image.style.margin = 'auto';

        // IE8 fix: check the width/height first
        if (newWidth>0) {
            image.style.width = newWidth + 'px';
        }
        if (newHeight>0) {
            image.style.height = newHeight + 'px' ;
        }
    };

    hide() {
        this.imageTagContainer.style.display = 'none';
    }

    show() {
        this.imageTagContainer.style.display = 'block';
    }
}

