import Imagelist from "./EventgalleryImageList";
import {forEach} from "../../common/js/Helpers";

/* processes a list of images and tries to resize separately*/
export default class EventsTiles extends Imagelist {
    constructor(newOptions) {
        super(newOptions);
    }

    processList() {
        let width = this.width;
        forEach(this.images, (image) => {
            let newHeight = Math.round(image.height / image.width * width);
            let newWidth = width;
            if (this.options.adjustMode == "height" && image.height > image.width) {
                newHeight = width;
                newWidth = Math.round(image.width / image.height * newHeight);
            }
            image.setSize(newWidth, newHeight);

        });
    }
};
