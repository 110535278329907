import {addUrlHashParameter, mergeObjects, removeUrlHashParameter} from "../../../common/js/Helpers";

export default class HistoryHandler {

    constructor(newOptions) {
        this.options = {
            onClose: () => {},
            window: window,
            history: history
        }
        this.options = mergeObjects(this.options, newOptions);
    }

    browserBackHappened = false;

    _close = () => {
        this.browserBackHappened = true;
        this.options.onClose();
    }

    /**
     * Add a cleaned history item on the stack,
     * watch for browser back
     */
    startUrlHistory() {
        if (!this.options.history) return;

        this.options.window.addEventListener('popstate', this._close)
        this.browserBackHappened = false;
        let url = this.options.window.location.href;

        url = removeUrlHashParameter(url, 'gid');
        url = removeUrlHashParameter(url, 'pid')

        this.options.history.replaceState(null, '', url);
        this.options.history.pushState(null,'', null);
    }

    /**
     * remove the listener
     * go one step back in the history if NO browser-back detected
     */
    endUrlHistory() {
        if (!this.options.history) return;
        this.options.window.removeEventListener('popstate', this._close)
        if (!this.browserBackHappened) this.options.history.go(-1);
    }

    /**
     * set the URL for the current slide
     * @param slide PhotoSwipeGallerySlide
     */
    adjustUrlHistory(slide) {
        if (!this.options.history) return;
        let url = this.options.window.location.href;

        url = addUrlHashParameter(url, 'gid', slide.gid);
        url = addUrlHashParameter(url, 'pid', slide.pid)

        this.options.history.replaceState(null, '', url);
    }
}









