import Polyfill from "../Polyfill";
import {Add2CartButtonHandler} from "./Add2CartButtonHandler";
import {Events} from "./CartEvents";
import {CartConnectorButtonHandler} from "./CartConnectorButtonHandler";

class CartUrls {
    removeUrl:string = "";
    add2cartUrl:string = "";
    getCartUrl:string = "";
    add2cartdialogurl:string = "";
}

export class CartProcessor {

    urls: CartUrls;
    add2CartButtonHandler: Add2CartButtonHandler;
    cartConnectorButtonHandler: CartConnectorButtonHandler;

    constructor() {
        // @ts-ignore
        this.urls = window.EventGalleryCartConfiguration as CartUrls || CartUrls;
        this.add2CartButtonHandler = new Add2CartButtonHandler(this, this.urls?.add2cartdialogurl);
        this.cartConnectorButtonHandler = new CartConnectorButtonHandler();
    }

    add(reference: string, quantity:number) {
        fetch(this.urls.add2cartUrl + '&' + reference + '&quantity=' + quantity)
            .then(response => response.json())
            .then(data => this.dispatchCartData(data));
    }

    remove(reference: string) {
        fetch(this.urls.removeUrl + '&' + reference)
            .then(response => response.json())
            .then(data => this.dispatchCartData(data));
    }

    loadCart() {
        fetch(this.urls.getCartUrl)
            .then(response => response.json())
            .then(data => this.dispatchCartData(data));
    }

    dispatchCartData(data: any) {
        document.dispatchEvent(Polyfill.createNewEvent(Events.reload, {cart: data}));
    }
}
