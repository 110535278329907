import Polyfill from "../Polyfill";

const sliderRootSelector = '.glide';
const sliderGroupSelector = '.glide__slides';
const basicMarkup = `
<div class="glide">
    <div class="glide__track" data-glide-el="track">
        <ul class="glide__slides">            
        </ul>
    </div>
    <div class="glide__arrows" data-glide-el="controls">
        <button class="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
        <button class="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
    </div>
</div>`;

export default class SliderTransformator {

    constructor(sourceGroupElement, sourceElementSelector, doShowNav) {
        this.elementSelector = sourceElementSelector;
        this.sourceGroupElement = sourceGroupElement;
        this.doShowNav = doShowNav;
        this.sourceElements = this.getSourceElements();
    }

    getSourceElements() {
        if (this.sourceGroupElement === null) {
            return null;
        }
        return this.sourceGroupElement.querySelectorAll(this.elementSelector);
    }

    transform(numberOfItemsPerSlide) {
        if (this.sourceGroupElement === null || this.sourceElements === null) {
            return null;
        }

        if (this.sourceElements.length === 0) {
            return null;
        }
        // cleanup
        let sliderRoot = this.sourceGroupElement.querySelector(sliderRootSelector);

        if (sliderRoot !== null) {
            sliderRoot.remove();
        }
        // transformation

        let sliderMarkup = document.createRange().createContextualFragment(basicMarkup);
        if (!this.doShowNav) {

            let controlElement = sliderMarkup.querySelector('.glide__arrows');

            Polyfill.removeHtmlElementNode(controlElement);
        }
        let sliderGroupElement = sliderMarkup.querySelector(sliderGroupSelector);
        let currentSliderElement = null;

        for(let index=0; index<this.sourceElements.length; index++) {
            let element = this.sourceElements[index];
            if (index % numberOfItemsPerSlide === 0) {
                currentSliderElement = document.createElement('li');
                currentSliderElement.className = 'glide__slide';
                sliderGroupElement.appendChild(currentSliderElement);
            }
            currentSliderElement.appendChild(element);
        }

        this.sourceGroupElement.appendChild(sliderMarkup);

        return this.sourceGroupElement.querySelector(sliderRootSelector);

    }

}
