import {MiniCart} from "./MiniCart";
import {Add2CartDialogContent} from "./Add2CartDialogContent";
import {CartProcessor} from "./CartProcessor";
import {Events} from "./CartEvents";

document.addEventListener('DOMContentLoaded', () => {
    try {
        let cartProcessor = new CartProcessor();

        document.addEventListener(Events.prepareAdd2cartDialogContent, () => {
            new Add2CartDialogContent(cartProcessor)
       });

        let carts = document.getElementsByClassName('eventgallery-minicart-container');
        if (carts.length == 0) return

        for (let i = 0; i < carts.length; i++) {
            new MiniCart(cartProcessor, carts[i] as HTMLElement, null);
        }

        cartProcessor.loadCart();
    } catch (e) {
        console.log(e);
    }

})

document.addEventListener('DOMContentLoaded', () => {
    let container = document.querySelector('.orderimages-container');
    if (!container) {
        return;
    }
    let doUseStickyImagetypeSelection = container.getAttribute("data-sticky-image-type-selection") === "true";

    let orderimagesHideButton: HTMLElement = container.querySelector('.orderimages-hide'),
        orderimagesShowButton: HTMLElement = container.querySelector('.orderimages-show'),
        orderimagesHelp: HTMLElement = container.querySelector('.orderimages-help');

    function closeImageTypeSelection(e:Event) {
        if (e) {
            e.preventDefault();
        }
        orderimagesHideButton.style.display= 'none';
        orderimagesHelp.style.display= 'none';
        orderimagesShowButton.style.display= 'block';
        makeCartButtonsVisible(false);
    }

    function openImageTypeSelection(e:Event) {
        if (e) {
            e.preventDefault();
        }
        orderimagesHideButton.style.display= 'block';
        orderimagesHelp.style.display= 'block';
        orderimagesShowButton.style.display= 'none';

        makeCartButtonsVisible(true);
    }

    function makeCartButtonsVisible(doShow:boolean) {
        let cartButtons = document.querySelectorAll<HTMLElement>('.eventgallery-add2cart');
        for(let i=0; i<cartButtons.length; i++) {
            cartButtons[i].style.display = doShow ? 'inline-block':'none';
        }
    }


    orderimagesShowButton.addEventListener('click', openImageTypeSelection);
    orderimagesHideButton.addEventListener('click', closeImageTypeSelection);

    if (doUseStickyImagetypeSelection) {
        orderimagesShowButton.style.display= 'none';
        orderimagesHideButton.style.display= 'none';
        makeCartButtonsVisible(true);
    }
    else
    {
        closeImageTypeSelection(null);
    }

});
