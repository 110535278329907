import {Add2CartDialogContent} from "./Add2CartDialogContent";
import Overlay from "../Overlay";
import {CartProcessor} from "./CartProcessor";
import EventsHandler from "../utils/eventshandler";
import {Events} from "./CartEvents";

/**
 * takes care about the add2cart buttons on the images:
 * - update the click events
 */

export class Add2CartButtonHandler {
    cartProcessor: CartProcessor;
    eventHandler:EventsHandler = new EventsHandler();
    add2cartdialogurl: string;

    constructor(cartProcessor: CartProcessor, add2cartdialogurl: string) {
        this.cartProcessor = cartProcessor;
        this.add2cartdialogurl = add2cartdialogurl;
        this.initializeCartButtons();
        document.addEventListener(Events.rebindAdd2CartButtons, () => {this.initializeCartButtons()})
    }

    initializeCartButtons() {
        let add2cartButtons = document.getElementsByClassName('eventgallery-openAdd2cart');
        Array.prototype.forEach.call(add2cartButtons, (btn: HTMLBaseElement)=> {
            this.eventHandler.off('click', btn);
            this.eventHandler.on('click', btn, (e:Event) => {this.openAdd2CartDialog(e)});
        });
    }

    openAdd2CartDialog(e: Event) {
        e.preventDefault();
        e.stopPropagation();

        let link: HTMLElement = e.target as HTMLElement;

        if (!link.getAttribute('data-id')) {
            link = link.parentElement;
        }

        let overlay = new Overlay();
        overlay.onLoad = () => {
            new Add2CartDialogContent(this.cartProcessor);
        };

        overlay.openOverlay(this.add2cartdialogurl + '&' + link.getAttribute('data-id'));

        return false;
    };
}
