import {getOuterHeight, mergeObjects} from "../../../common/js/Helpers";
import Polyfill from "../Polyfill";
import {CartProcessor} from "./CartProcessor";
import {Events} from "./CartEvents";

enum MiniCartMode {
    short,
    long
}

class MiniCartOptions {
    buttonShowType:string =  'inline';
    emptyCartSelector:string = '.eventgallery-empty-minicart';
    cartSelector:string = '.eventgallery-minicart';
    cartItemContainerSelector:string = '.cart-items-container';
    cartItemsSelector:string = '.cart-items';
    cartItemSelector:string = '.cart-items .cart-item';
    cartCountSelector:string = '.itemscount';
    buttonDownSelector:string = '.toggle-down';
    buttonUpSelector:string = '.toggle-up';
    cartItemsMinHeight:number = null;
    removeLinkTitle:string = "Remove";
}

export class MiniCart {
    containerElement: HTMLElement;
    cartElement: HTMLElement;
    cartItemsElement: HTMLElement;
    emptyCartElement: HTMLElement;
    buttonToShort: HTMLElement;
    buttonToLong: HTMLElement;
    options: MiniCartOptions;
    lineItems: any;
    cartProcessor: CartProcessor;
    minicartMode: MiniCartMode = MiniCartMode.short;

    constructor(cartProcessor: CartProcessor, containerElement: HTMLElement, options:MiniCartOptions) {
        this.containerElement = containerElement;
        this.cartProcessor = cartProcessor;
        this.lineItems = [];

        this.options = mergeObjects(new MiniCartOptions(), options);
        // @ts-ignore
        let globalConfigStore = window.EventGalleryCartConfiguration;
        this.options = mergeObjects(this.options, globalConfigStore);

        this.initUI();

        document.addEventListener(Events.reload, (e: CustomEvent)=>this.updateCartData(e.detail));
        document.addEventListener(Events.rebindAdd2CartButtons, () => {this.updateAdd2CartIcons()})
    }

    initUI() {
        this.cartElement = this.containerElement?.querySelector(this.options.cartSelector);
        this.cartItemsElement = this.cartElement?.querySelector(this.options.cartItemsSelector);
        this.emptyCartElement = this.containerElement?.querySelector(this.options.emptyCartSelector);
        this.buttonToShort = this.cartElement?.querySelector(this.options.buttonUpSelector);
        this.buttonToLong = this.cartElement?.querySelector(this.options.buttonDownSelector);

        this.buttonToLong.addEventListener('click', () => {this.resizeCartArea(MiniCartMode.long)});
        this.buttonToShort.addEventListener('click', () => {this.resizeCartArea(MiniCartMode.short)});
    }

    _generateId(lineItem: any) {
        return 'folder=' + encodeURIComponent(lineItem.folder) + '&file=' + encodeURIComponent(lineItem.file);
    }

    updateAdd2CartIcons() {

        let elements = document.querySelectorAll('.eventgallery-add2cart i.egfa');
        for(let i=0; i<elements.length; i++) {
            elements[i].classList.remove('egfa-shopping-cart');
            elements[i].classList.add('egfa-cart-plus');
        }

        // mark the add2cart link to show the item is already in the cart
        for (let i = 0; i<this.lineItems.length; i++) {
            let id = this._generateId(this.lineItems[i])

            let elements = document.querySelectorAll(`.eventgallery-add2cart[data-id*='${id}'] i.egfa`);
            for (let i = 0; i < elements.length; i++) {
                elements[i].classList.add('egfa-shopping-cart');
                elements[i].classList.remove('egfa-cart-plus');
            }
        }
    }

    resizeCartArea(miniCartMode: MiniCartMode) {
        this.minicartMode = miniCartMode;

        let amountOfLines:number = 1;
        let lineHeight = -1;

        let lastY:number = -1;
        let cartElements = this.cartItemsElement.querySelectorAll(this.options.cartItemSelector);
        for(let i = 0; i<cartElements.length; i++) {
            let cartElement: HTMLElement = cartElements[i] as HTMLElement;
            if (lineHeight<0) {
                lineHeight = getOuterHeight(cartElement);
            }
            if (lastY>-1 && lastY != cartElement.offsetTop) {
                amountOfLines++
            }
            lastY = cartElement.offsetTop;
        }

        let visibleAmountOfLines = 1;

        if (amountOfLines === 1) {
            this.buttonToShort.style.display = 'none';
            this.buttonToLong.style.display = 'none';
        } else {
            if (this.minicartMode == MiniCartMode.short) {
                this.buttonToShort.style.display = 'none';
                this.buttonToLong.style.display = 'inline';
            } else {
                this.buttonToShort.style.display = 'inline';
                this.buttonToLong.style.display = 'none';
                visibleAmountOfLines = amountOfLines;
            }
        }

        this.cartItemsElement.style.height = (visibleAmountOfLines*lineHeight) + 'px';
    }

    updateCartData(data:any) {

        this.lineItems = data.cart;

        if (this.lineItems.length>0) {
            this.cartElement.style.display = 'block';
            if (this.emptyCartElement) this.emptyCartElement.style.display = 'none';
        } else {
            this.cartElement.style.display = 'none';
            if (this.emptyCartElement) this.emptyCartElement.style.display = 'block';
        }

        let cartItemsHTML = '';
        for (let i = this.lineItems.length - 1; i >= 0; i--) {
            cartItemsHTML = cartItemsHTML +
                `<div>
                    <div class="cart-item">
                        <span class="badge badge-pill badge-info">${this.lineItems[i].count}</span>
                        ${this.lineItems[i].imagetag}
                        <a href="#" title="${this.options.removeLinkTitle}" 
                            class="button-removeFromCart eventgallery-removeFromCart" 
                            data-id="lineitemid=${this.lineItems[i].lineitemid}">
                            <i class="egfa egfa-2x egfa-remove"></i>
                        </a>
                    </div>
                </div>`;
        }

        this.cartItemsElement.innerHTML = cartItemsHTML;

        let newLineItemElements = this.cartItemsElement.querySelectorAll('.eventgallery-removeFromCart');
        for(let i = 0; i<newLineItemElements.length; i++) {
            newLineItemElements[i].addEventListener('click', (e: Event) => this.remove(e));
        }

        this.updateAdd2CartIcons();
        this.resizeCartArea(this.minicartMode);

        this.cartElement.querySelector('.itemscount').innerHTML =  this.lineItems.length;

        // force the lightbox to rescan the page so we can open cart items in a lightbox.
        // @ts-ignore
        if (Eventgallery !== undefined && Eventgallery.lightbox !== undefined) {
            // @ts-ignore
            Eventgallery.lightbox.reload();
        }
        // tigger the loading of Google Photos images for example.
        document.dispatchEvent(Polyfill.createNewEvent('eventgallery-images-added', {isOverlay: true}));
    }

    remove(e:Event) {
        e.preventDefault();

        let button: HTMLElement = e.target as HTMLElement;
        let reference:string;
        if (button.getAttribute('data-id')) {
            reference = button.getAttribute('data-id');
        } else {
            reference = button.parentElement.getAttribute('data-id');
        }


        this.cartProcessor.remove(reference);
    }
}
