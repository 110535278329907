export default class EventgalleryLazyload {

    static instance = null;
    static knownImageUrls = [];

    constructor() {
        this.options = {
            elementSelector: 'img.eventgallery-lazyme',
            classForLoaded: 'eventgallery-lazyload-loaded',
            classForLoading: 'eventgallery-lazyload-loading'
        }

        if ('IntersectionObserver' in window &&
            'IntersectionObserverEntry' in window &&
            'intersectionRatio' in window.IntersectionObserverEntry.prototype) {

            // Minimal polyfill for Edge 15's lack of `isIntersecting`
            // See: https://github.com/w3c/IntersectionObserver/issues/211
            if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
                Object.defineProperty(window.IntersectionObserverEntry.prototype,
                    'isIntersecting', {
                        get: function () {
                            return this.intersectionRatio > 0;
                        }
                    });
            }


            this.imageObserver = new IntersectionObserver((entries, imgObserver) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const lazyImage = entry.target;
                        if (lazyImage.dataset.src) {
                            lazyImage.setAttribute('srcset', lazyImage.dataset.src);

                            if (!EventgalleryLazyload.knownImageUrls.includes(lazyImage.dataset.src)) {
                                EventgalleryLazyload.knownImageUrls.push(lazyImage.dataset.src);
                                lazyImage.classList.add('eventgallery-lazyload-loading');
                                lazyImage.classList.remove('eventgallery-lazyload-loaded');

                                let img = document.createElement('img');
                                img.src = lazyImage.dataset.src;
                                img.onload = () => {
                                    lazyImage.classList.remove('eventgallery-lazyload-loading');
                                    lazyImage.classList.add('eventgallery-lazyload-loaded');
                                }
                            }
                        }
                    }
                })
            });
        }
    }

    static watchImages() {
        if (!EventgalleryLazyload.instance) {
            EventgalleryLazyload.instance = new EventgalleryLazyload();
        } else {
            return;
        }

        let lazyImages = document.querySelectorAll(EventgalleryLazyload.instance.options.elementSelector);

        for (let i = 0; i < lazyImages.length; i++) {
            let lazyImage = lazyImages[i];

            if (lazyImage.dataset.src) {
                if (EventgalleryLazyload.instance.imageObserver) {
                    EventgalleryLazyload.instance.imageObserver.observe(lazyImage);
                } else {
                    lazyImage.setAttribute('srcset', lazyImage.dataset.src);
                }
            }
        }
    }
}
