export default class ElementSize {
    /**
     *
     * @param element
     * @returns float
     */
    static getOuterWidth(element) {
        var domRect = element.getBoundingClientRect();
        let computedStyle = getComputedStyle(element);
        let marginLeft = parseFloat(computedStyle.marginLeft);
        let marginRight = parseFloat(computedStyle.marginRight);
        return domRect.width + marginLeft + marginRight;
    }

    /**
     * Tries to find out, how many elements we can fit into the container so
     * they do not wrap.
     *
     * @param containerElement
     * @param element
     * @param numberOfRows
     * @returns {number}
     */
    static calclateNumberOfFittingItemsPerSlide(containerElement, element, numberOfRows) {

        if (null == element || containerElement == null) {
            return 1;
        }

        var domRect = containerElement.getBoundingClientRect();
        let numberOfItemsPerSlide = Math.floor(domRect.width / ElementSize.getOuterWidth(element)) * numberOfRows;


        if (numberOfItemsPerSlide < 1) {
            numberOfItemsPerSlide = 1 * numberOfRows;
        }

        return numberOfItemsPerSlide;
    }
}
