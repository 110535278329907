import Imagelist from "./EventgalleryImageList";
import {forEach} from "../../common/js/Helpers";

/**
 * Formats a list of images to appear square sized. This class is doing something like the Grid layout for events.
 * @param newOptions
 * @constructor
 */
export default class SquareList extends Imagelist {
    constructor(newOptions) {
        super(newOptions);
    }

    processList() {
        var width = this.width;
        forEach(this.images, (image) => {
            image.setSize(width, width);
        });
    };
}
