/**
 * splits a hash value into its elements. returns an empty object if there is nothing in
 */
export function _parseHashString(hash) {
    let params = {};

    if(hash.length < 5) {
        return params;
    }

    let vars = hash.split('&');
    for (let i = 0; i < vars.length; i++) {
        if(!vars[i]) {
            continue;
        }
        let pair = vars[i].split('=');
        if(pair.length < 2) {
            continue;
        }
        params[pair[0]] = pair[1];
    }

    return params;
}

export function parseHash() {
    return _parseHashString(window.location.hash.substring(1));
}


