
export default class PhotoSwipeGallerySlide {
    constructor() {
        this.width = 0;
        this.height = 0;
        this.src = '';
        this.alt = '';
        this.title = '';
        this.pid = '';
        this.gid = '';
        this.thumbEl = null;
        this.msrc= '';
        this.shareUrl = '';
        this.add2cartUrl = '';
        this.directDownloadButton = '';
        this.type = '';
        this.videoSrc = '';
        this.videoSources = null;
    }

    hash() {
        return this.gid + this.src;
    }
}
