export default class Polyfill {
    static removeHtmlElementNode(element:any) {
            if (element.parentNode !== null)
                element.parentNode.removeChild(element);
    }

    static createNewEvent(eventName:string, data:any) {
        let event = null;

        if(typeof(CustomEvent) === 'function') {
            event = new CustomEvent(eventName, {detail: data});
        }else{
            event = document.createEvent('Event');
            event.initEvent(eventName, true, true);
            // @ts-ignore
            event.detail = data;
        }
        return event;
    }
}
