import EventsHandler from "../utils/eventshandler";
let eventsHandler = new EventsHandler();

export function addTouch(element:HTMLElement, leftAction:Function, rightAction:Function, tabAction:Function, bottomAction:Function) {

    let hDistance:number;
    let vDistance:number;


    let vSwipe = false;
    let hSwipe = false;
    let hSwipMinDistance = 50;
    let vSwipMinDistance = 50;
    let swipMinDistanceForClick = 5;
    let startCoords: Touch;
    let endCoords: Touch;

    eventsHandler.off('touchstart', element);
    eventsHandler.off( 'touchend', element);

    eventsHandler.on('touchstart', element, (event:TouchEvent)=>{
        startCoords = event.targetTouches[0];
    }, false, true);


    eventsHandler.on('touchend', element, (event:TouchEvent)=>{

        endCoords = event.changedTouches[0];
        if (!endCoords) {
            return false;
        }

        vDistance = endCoords.pageY - startCoords.pageY;
        hDistance = endCoords.pageX - startCoords.pageX;


        vDistance = endCoords.pageY - startCoords.pageY;
        if ( Math.abs( vDistance ) >= vSwipMinDistance ) {
            vSwipe = true;
        }

        hDistance = endCoords.pageX - startCoords.pageX;

        if ( ! vSwipe && Math.abs( hDistance ) >= hSwipMinDistance ) {
            hSwipe = true;
        }

        // Swipe to bottom to close
        if ( vSwipe ) {
            vSwipe = false;
            if ( Math.abs( vDistance ) >= 2 * vSwipMinDistance ) {
                bottomAction && bottomAction();
            }

        } else if ( hSwipe ) {

            hSwipe = false;

            // swipeLeft
            if( hDistance >= hSwipMinDistance) {
                leftAction && leftAction();
            // swipeRight
            } else if ( hDistance <= -hSwipMinDistance) {
                rightAction && rightAction();
            }

        } else if (Math.abs(vDistance)<swipMinDistanceForClick && Math.abs(hDistance)<swipMinDistanceForClick) {
            if (undefined !== tabAction)
            {
                tabAction();
            } else {
                let target:HTMLElement = event.target as HTMLElement;
                target.click();
            }
        }
        return true;

    }, false, true);



}


