import URLHelper from "./URLHelper";

const LOADING_MATCHER = 'gp.svg';
const MARKER_IMAGE_IS_IN_PROGRESS_ATTRIBUTE = 'data-eg-gp-processing';

export default class GooglePhotosProcessor {



    constructor() {

        this.albumUrl = "";
        this.albumsUrl = "";

        // noinspection JSUnresolvedVariable
        if (window.EventGalleryGooglePhotosConfiguration) {
            this.albumUrl = window.EventGalleryGooglePhotosConfiguration.albumUrl;
            this.albumsUrl = window.EventGalleryGooglePhotosConfiguration.albumsUrl;
        }

        this.imageAddedCallback = (e) => this.processImages(e);
        document.addEventListener("eventgallery-images-added", this.imageAddedCallback, true);
    };

    destroy() {
        document.removeEventListener("eventgallery-images-added", this.imageAddedCallback, true);
    }

    // noinspection JSUnusedGlobalSymbols
    processImages(e) {
        let doReloadLightbox = true;
        if (e?.detail?.isOverlay) {
            doReloadLightbox = false;
        }
        let albums = this._groupByAlbum(this._collectHTMLElements());
        this._markImagesAsInProgress(albums);
        this._getMainImageDataFromServer(albums, doReloadLightbox);
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Grabs IMG tag items from the DOM for Google Photos image placeholder.
     *
     * @returns <Element>[]
     * @private
     */
     _collectHTMLElements() {
        let htmlCollection = document.getElementsByTagName('IMG');

        let foundImageHTMLElements = /** @type {HTMLElement} */[...htmlCollection];

        let result = foundImageHTMLElements.filter(img => {
            let imageSrcSet = img.getAttribute('srcset');
            let dataSrc = img.getAttribute('data-src');
            let src = img.src;

            if (img.getAttribute(MARKER_IMAGE_IS_IN_PROGRESS_ATTRIBUTE) === '1') {
                return false;
            }

            return (imageSrcSet && imageSrcSet.indexOf(LOADING_MATCHER)>0)
                || (dataSrc && dataSrc.indexOf(LOADING_MATCHER)>0)
                || (src && src.indexOf(LOADING_MATCHER)>0);
        });

        htmlCollection = document.getElementsByTagName('A');
        let foundLinkHTMLElements = /** @type {HTMLElement} */[...htmlCollection];
        result = result.concat(foundLinkHTMLElements.filter(a => {
            let dataSrc = a.getAttribute('data-src');
            let rel = a.getAttribute('rel');
            let href = a.getAttribute('href');

            if (a.getAttribute(MARKER_IMAGE_IS_IN_PROGRESS_ATTRIBUTE) === '1') {
                return false;
            }
            return (href && href.indexOf(LOADING_MATCHER)>0)
                || (dataSrc && dataSrc.indexOf(LOADING_MATCHER)>0)
                || (rel && rel.indexOf(LOADING_MATCHER)>0);
        }));

        return result;

    }

    /**
     *
     * @param albums {Map<any, any>}
     * @private
     */
    _markImagesAsInProgress(albums) {
        for (let [foldername, album] of albums) {
            /**
             * @var ParsedHTMLElement parsedHTMLElement
             */
            album.forEach(parsedHTMLElement => {
              parsedHTMLElement.getHTMLElement().setAttribute(MARKER_IMAGE_IS_IN_PROGRESS_ATTRIBUTE, 1);
            })
        }
    }

    /**
     * sorts all found images into a map: key=folder, value=array of
     *
     * @param htmlElements
     * @returns {Map<any, any>}
     * @private
     */
    _groupByAlbum(htmlElements) {
        let albums = new Map();
        htmlElements.forEach((imageHTMLElement) => {
           let parsedHTMLElement = this._parseHTMLElement(imageHTMLElement);
            parsedHTMLElement.updateParameters();
           let folder = parsedHTMLElement.parameters.get('folder');

           if (!albums.get(folder)) {
             albums.set(folder, []);
           }

           albums.get(folder).push(parsedHTMLElement);
        });

        return albums;
    }

    // noinspection JSMethodCanBeStatic
    /**
     *
     * @param htmlElement
     * @returns {ParsedHTMLElement}
     * @private
     */
    _parseHTMLElement(htmlElement) {
        if (htmlElement.tagName === 'IMG') {
            return new ParsedImageHTMLElement(htmlElement);
        }
        return new ParsedLinkHTMLElement(htmlElement);
    }


    /**
     * starts several requests to the server to determine
     *
     * @param albums
     * @private
     */
    _getAlbumDataFromServer(albums, doReloadLightbox) {
        for (let [foldername, album] of albums) {
            this._doAlbumRequest(foldername, album, doReloadLightbox);
        }
    }

    _doAlbumRequest(foldername, album, doReloadLightbox) {
        if (!this.albumUrl || !foldername) return;
        fetch(this.albumUrl + '&folder=' + foldername)
            .then(response => response.json())
            .then(data => this._processAlbumResult(album, data, doReloadLightbox));
    }

    _processAlbumResult(album, data, doReloadLightbox) {
        this._replaceImages(album, data, doReloadLightbox);
    }

    _getMainImageDataFromServer(albums, doReloadLightbox) {
        let albumsWithMainImagesOnly = this._filterForAlbumsWithMainImageOnly(albums);

        if (albumsWithMainImagesOnly.size > 0) {
            this._doAlbumsRequest(albumsWithMainImagesOnly, albums, doReloadLightbox);
            for (let [foldername, album] of albumsWithMainImagesOnly) {
                albums.delete(foldername);
            }
        }

        this._getAlbumDataFromServer(albums, doReloadLightbox);
    }

    _doAlbumsRequest(albumsWithMainImagesOnly, albums, doReloadLightbox) {
        if (!this.albumsUrl) return;
        fetch(this.albumsUrl)
            .then(response => response.json())
            .then(data => this._processAlbumsResult(albumsWithMainImagesOnly, albums, data, doReloadLightbox));
    }

    _processAlbumsResult(albumsWithMainImagesOnly, albums, data, doReloadLightbox) {
        for (let [key, album] of albumsWithMainImagesOnly) {
            this._replaceImages(album, data[key], doReloadLightbox);
        }
        this._getAlbumDataFromServer(albums, doReloadLightbox);
    }

    // noinspection JSMethodCanBeStatic
    _filterForAlbumsWithMainImageOnly(albums) {
        let newAlbums = new Map();
        for(let [key, album] of albums) {
            let mainImages = album.filter(image => image.isMainImage());

            if (mainImages.length> 0 && mainImages.length === album.length) {
                newAlbums.set(key, album);
            }
        }
        return newAlbums;
    }

    _replaceImages(album, serverResult, doReloadLightbox) {
        if (album === undefined || serverResult === undefined) {
            return;
        }

        album.forEach(parsedImageHTMLElement => {
            let imageUrl = serverResult[parsedImageHTMLElement.getFile()];
            if (imageUrl === undefined) {
                return false;
            }

            parsedImageHTMLElement.updateParameters();
            parsedImageHTMLElement.replaceElementLinks(imageUrl);
            parsedImageHTMLElement.getHTMLElement().setAttribute(MARKER_IMAGE_IS_IN_PROGRESS_ATTRIBUTE, 0);

        });

        if (doReloadLightbox && window.Eventgallery?.lightbox) {
            if (Eventgallery.lightbox.isOpen()) {
                let link = Eventgallery.lightbox.getCurrentSlide().thumbEl;
                Eventgallery.lightbox._gallery.close();
                setTimeout(()=>link.click(),500);
            }
        }
    }
};

class ParsedHTMLElement {
    constructor(htmlElement) {
        this.htmlElement = htmlElement;
        this.parameters = null;
        this.attributeNames = new Set();
    }

    updateParametersWithUrl(url) {
        let parameterString = url.substring(url.indexOf('#')+1);
        this.parameters = URLHelper.parseURLParameter(parameterString);
    }

    updateParameters() {
        for(let n of this.attributeNames) {
            let url = this.getHTMLElement().getAttribute(n);
            if (url && url.indexOf(LOADING_MATCHER)>0) {
                this.updateParametersWithUrl(url);
                return;
            }
        }
    }


    replaceElementLinks(imageUrl) {

        for(let n of this.attributeNames) {
            let url = this.getHTMLElement().getAttribute(n);
            if (url && url.indexOf(LOADING_MATCHER)>0) {
                this.getHTMLElement().setAttribute(n, this.getImageUrl(imageUrl, this.getWidth(url)));
            }
        }
    }

    getHTMLElement() {
        return this.htmlElement;
    }

    getFolder() {
        return this.parameters.get('folder');
    }

    getFile() {
        return this.parameters.get('file');
    }


    getWidth(url) {
        return URLHelper.parseURLParameter(url).get('width');
    }

    getImageUrl(imageUrl, width) {
        return imageUrl + '=w' + width;
    }

    isMainImage() {
        return this.parameters.get('m') === '1';
    }
}

class ParsedLinkHTMLElement extends ParsedHTMLElement {

    constructor(htmlElement) {
        super(htmlElement);
        this.attributeNames = new Set(['href', 'data-src', 'rel']);
    }

}

class ParsedImageHTMLElement extends ParsedHTMLElement{

    constructor(htmlElement) {
        super(htmlElement);
        this.attributeNames = new Set(['src', 'data-src', 'srcset']);
    }
}
