import PhotoSwipeGallery from "./photoswipe/PhotoSwipeGallery";

/**
 * Builds a slide show on top of PhotoSwipe.
 */
export default class SlideShow {

    /**
     *
     * @param photoSwipeGallery PhotoSwipe
     */
    constructor(photoSwipe) {
        if (!window.EventGalleryLightboxConfiguration) {
            return;
        }

        if (!window.EventGalleryLightboxConfiguration.doUseSlideshow) {
            return;
        }

        this._photoSwipe = photoSwipe;
        this._SlideShow_Running = false;
        this._currentTimer = null;
        this._SlideShow_Delay = window?.EventGalleryLightboxConfiguration?.slideshowSpeed || 2000 /*ms*/;
        this._SlideShow_Button = null;
        this._photoSwipe.ui.registerElement({
            name: 'playpause-button',
            order: 9,
            isButton: true,
            html: '',
            onInit: (el) => {
                this._SlideShow_Button = el;
                this._adjustNavigation();
            },
            onClick: (event, el) => {
                //console.log('slideshow click')
                return this.toggleSlideShowState();
            }
        });

        this._photoSwipe.on('change', () => {
            this._planNextSwitch(false);
        });

        this._photoSwipe.on('destroy', () => this._photoSwipeGallery=null);
        this._photoSwipe.on('keydown',     (e) => {if (e.originalEvent.target !== this._SlideShow_Button) this._stopSlideshow()} )
        this._photoSwipe.on('pointerDown', (e) => {if (e.originalEvent.target !== this._SlideShow_Button) this._stopSlideshow()} )

        if (window.EventGalleryLightboxConfiguration.doUseAutoplay) {
            this.toggleSlideShowState();
        }
    }

    /**
     *
     * @param delay int
     */
    setDelay(delay) {
        this._SlideShow_Delay = delay;
    }

    /**
     * switches the slide show on/off depending on the current state
     */
    toggleSlideShowState() {

        if (this._SlideShow_Running) {
            this._stopSlideshow();
        } else {
            this._startSlideshow();
        }
    }

    _stopSlideshow() {
        this._SlideShow_Running = false;
        this._adjustNavigation();
    }

    _startSlideshow() {
        this._SlideShow_Running = true;
        this._planNextSwitch(true);
        this._adjustNavigation();
    }

    /**
     * make sure the navigation displays the current slide show state.
     */
    _adjustNavigation() {
        if (this._SlideShow_Button) {
            this._SlideShow_Button.classList.remove(this._SlideShow_Running ? "play" : "pause");
            this._SlideShow_Button.classList.add(this._SlideShow_Running ? "pause" : "play");
            //this._SlideShow_Button.setAttribute('title', this._SlideShow_Running ? this._SlideShow_Button.getAttribute('data-title-pause') : this._SlideShow_Button.getAttribute('data-title-play'));
        }
    }

    /**
     * create the timeout for the next switch.
     *
     * @param isForTheFirstSwitch Boolean changes the switch time so the first switch can be faster
     */
    _planNextSwitch(isForTheFirstSwitch)  {
        clearTimeout(this._currentTimer);
        this._currentTimer = setTimeout(() => this._nextSlide(), isForTheFirstSwitch ? this._SlideShow_Delay / 2.0 :this._SlideShow_Delay);
    }

    /**
     * change to the next slide
     */
    _nextSlide() {
        if (this._photoSwipe === null) {
            return;
        }

        if (!this._SlideShow_Running) {
            return;
        }

        this._photoSwipe.next();
    }
}
