import {forEach, mergeObjects} from "../../common/js/Helpers";

// create a tile layout and centers images in a tile
export default class TilesCollection {

    constructor(newOptions) {

        this.options = {
            tiles: null, // document.querySelector('#events-tiles .event')
            tilesContainer: [] //'#events-tiles .event-tiles'
        };

        this.options = mergeObjects(this.options, newOptions);
        this.tiles = this.options.tiles;
        this.tilesContainer = this.options.tilesContainer;
    };


    calculate() {
        let tilesPerRow = 1;

        // reset grid to support resize and media queries
        forEach(this.tiles, (tile) => {
            tile.style.visibility = 'hidden';
            tile.style.position = 'static';
            tile.style.float = 'left';
        })

        if (!this.tiles.length) {
            return;
        }

        let firstExampleTile = this.tiles[0];
        let columnWidth = firstExampleTile.offsetWidth;
        let y = firstExampleTile.offsetTop;

        for (let i = 1; i < this.tiles.length; i++) {
            if (this.tiles[i].offsetTop !== y) {
                break;
            }
            tilesPerRow++;
        }

        // create array of height values for the columns
        let columnHeight = [];
        for (let i = 0; i < tilesPerRow; i++) {
            columnHeight.push(0);
        }

        forEach(this.tiles, (tile)=> {
            let smallestColumn = this.getSmallestColumn(columnHeight);
            tile.style.left = smallestColumn * columnWidth + 'px';
            tile.style.top = columnHeight[smallestColumn] + 'px';
            columnHeight[smallestColumn] = columnHeight[smallestColumn] + tile.offsetHeight;

            tile.style.visibility = 'visible';
            tile.style.position = 'absolute';
            tile.style.float = 'none';
        });

        this.tilesContainer.style.height = columnHeight[this.getHighestColumn(columnHeight)] + 'px';
    };

    /*
    * returns the position of the smallest value in the array
    */
    getSmallestColumn(columnHeight) {

        let smallestColumnValue = columnHeight[0];
        let smallestColumnNumber = 0;

        for (let i = 0; i < columnHeight.length; i++) {
            if (smallestColumnValue > columnHeight[i]) {
                smallestColumnValue = columnHeight[i];
                smallestColumnNumber = i;
            }

        }
        return smallestColumnNumber;

    };

    /*
    * returns the position of the highest value in the array
    */
    getHighestColumn(columnHeight) {

        let columnValue = columnHeight[0];
        let columnNumber = 0;

        for (let i = 0; i < columnHeight.length; i++) {
            if (columnValue < columnHeight[i]) {
                columnValue = columnHeight[i];
                columnNumber = i;
            }

        }
        return columnNumber;

    };
}
