
/**
 * Indicates whether the specified value is a string.
 *
 * @param  {*}   value
 * @return {Boolean}
 */
export function isString (value: any) {
    return typeof value === 'string'
}

export function isArray(value: any) {
    return Array.isArray(value);
}

export function isNodeList(value: any) {
    return NodeList.prototype.isPrototypeOf(value);
}
