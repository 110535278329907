import Anchor from "../glide_modules/anchor"
import Glide, { Controls, Breakpoints, Swipe, Autoplay, Images  } from '@glidejs/glide/dist/glide.modular.esm'
import SliderTransformator from "./SliderTransformator";
import SliderOptions from "./SliderOptions";
import ElementSize from "../utils/ElementSize";
import EventgalleryLazyload from "../EventgalleryLazyload";

export default class SliderStarter {

    constructor(sourceGroupElement) {
        this.sourceGroupElement = sourceGroupElement;
        this.sliderTransformator = null;
    }

    start() {

        this.sliderOptions = SliderStarter._loadOptions(this.sourceGroupElement);

        let sourceElementSelector = this.sliderOptions.getSlidesElementsSelector();
        let autoplay = this.sliderOptions.getAutoPlay();
        let doShowNav = this.sliderOptions.doShowNav();
        let numberOfItemsPerSlide = ElementSize.calclateNumberOfFittingItemsPerSlide(this.sourceGroupElement, this.sourceGroupElement.querySelector(sourceElementSelector), this.sliderOptions.getNumberOfRowsPerSlide());

        if (null == this.sliderTransformator) {
            this.sliderTransformator = new SliderTransformator(this.sourceGroupElement, sourceElementSelector, doShowNav);
        }
        let rootElement = this.sliderTransformator.transform(numberOfItemsPerSlide);

        if (rootElement === null) {
            return;
        }

        EventgalleryLazyload.watchImages();

        let glide = new Glide(rootElement, {
            type: 'carousel',
            perView: 1,
            gap: 15,
            keyboard: false,
            autoplay: autoplay
        });

        glide.mount({ Swipe, Controls, Breakpoints, Autoplay, Anchor, Images  });

    }

    static _loadOptions(element) {
        return new SliderOptions(element);
    }


}
