import Polyfill from "./Polyfill";
import {insertAndExecute} from "../../common/js/Helpers";


/**
 * this class is the basic modal of Event Gallery.
 */
export default class Overlay {

    /**
     * @param parent HTML element to append the modal to.
     */
    constructor(parent = document.body) {
        this.isOpen = false;
        this.myDiv = null;
        this.background = null;
        this.parent = parent
        this.onClosed = undefined;
        this.onLoad = undefined;
        this.repositionTimer = null;
        this.windowWidth = 0;
        this.onResizeOverlay = () => {
            this._setRositionTimer(false);
        }
    }

    /**
     * opens an overlay. The content can be a url or content.
     * Url start with http or /
     *
     * @param dataUrl
     */
    openOverlay(dataUrl) {

        this.isOpen = true;
        this.myDiv = document.createElement('div');
        this.myDiv.id = 'eventgallery-overlay';
        this.myDiv.innerHTML = '<i class="egfa egfa-2x egfa-cog egfa-spin"></i>';
        this.myDiv.style.opacity = '1 !important';
        this.myDiv.style.position = 'absolute';
        this.myDiv.style.maxWidth = '100%';

        this.background = document.createElement('div');
        this.background.id = 'eventgallery-overlay-background';
        this.background.addEventListener('click', () => {
            this.closeOverlay()
        });

        this.parent.append(this.background);
        this.parent.append(this.myDiv);

        this.reposition(true);

        if (dataUrl.startsWith('http') || dataUrl.startsWith('/')) {
            Overlay._getRemoteData(dataUrl, (data) => {
                this._addContent(data);
            })
        } else {
            this._addContent(`<button class="btn-close-overlay eventgallery-close-overlay"><i class="egfa egfa-2x egfa-times-circle"></i></button>` + dataUrl);
        }

        window.addEventListener('resize', this.onResizeOverlay);
    }

    /**
     * triggers a timeout for the repositioning. This will avoid too much flickering.
     *
     * @param force
     * @private
     */
    _setRositionTimer(force) {
        if (this.repositionTimer) {
            clearTimeout(this.repositionTimer)
        }
        this.repositionTimer = setTimeout(() => this.reposition(force), 500);
    }

    static _getRemoteData(url, callback) {
        fetch(url)
            .then(response => response.text())
            .then(data => callback(data));
    }

    /**
     * moves the given content into the content overlay. Executes the onload method when finished.
     *
     * @param content
     * @private
     */
    _addContent(content) {
        insertAndExecute(this.myDiv, content);

        let elements = this.myDiv.getElementsByClassName('eventgallery-close-overlay');
        for(let i=0; i<elements.length;i++) {
            elements[i].addEventListener('click', (e) => {
                e.preventDefault();
                this.closeOverlay()
            });
        }

        this.reposition(true);

        if (this.onLoad !== undefined) {
            this.onLoad();
        }

        document.dispatchEvent(Polyfill.createNewEvent('eventgallery-images-added', {isOverlay: true}));
    }

    /**
     * Closes the overlay and fires the onclose method if defined.
     */
    closeOverlay() {
        this.isOpen = false;

        window.removeEventListener('resize', this.onResizeOverlay);

        Polyfill.removeHtmlElementNode(this.myDiv);
        Polyfill.removeHtmlElementNode(this.background);
        if (this.onClosed !== undefined) {
            this.onClosed();
        }
    }

    /**
     * Move the overlay to a good position.
     *
     * @param force defines if we force the reposition. If set to false, we do it only in case the width of the window changed.
     */
    reposition(force) {

        if (this.repositionTimer !== null) {
            clearTimeout(this.repositionTimer);
        }

        let maxWidth = document.body.clientWidth;

        if (!force) {
            if (this.windowWidth === maxWidth) {
                return;
            }
        }

        this.windowWidth = maxWidth;

        this.myDiv.style.opacity = 0;
        this.myDiv.style.top = 0;
        this.myDiv.style.left = 0;


        let maxHeight = window.innerHeight,
            width = this.myDiv.offsetWidth,
            height = this.myDiv.offsetHeight,
            scrollTop = 0,
            left = 0;

        // this allows the overlay to support two different containers:
        // 1. the body (which is the default)
        // 2. a full page html element which is positioned absolute
        // we can make this more complicated later

        if (this.parent == document.body) {
            scrollTop = window.pageYOffset
        }

        let top = scrollTop;

        if (maxWidth - width > 0) {
            left = (maxWidth - width) / 2;
        }

        if (maxHeight - height > 0) {
            top = scrollTop + (maxHeight - height) / 2;
        }

        this.myDiv.style.top = top + 'px';
        // there is a margin of 3px in overlay.less => we should not set this more to the left.
        if (left > 3) {
            this.myDiv.style.left = left + 'px';
        }

        this.myDiv.style.opacity = 1;
    };
}
