import {getParent, insertAndExecute} from "../../../common/js/Helpers";

export class DownloadButton {
    static download(e:Event, href:string, download:string) {
        e.preventDefault();
        let a = document.createElement('a');
        a.href = href;
        a.setAttribute("target", "_blank");
        a.download = download;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a)
    }
}

export class ClickableButton {
    static click(e:Event, href:string) {
        e.preventDefault();
        document.location.href = href;
    }
}
