import {CartProcessor} from "./CartProcessor";

/**
 * Helps to bring the content of the add2cart dialog to life. e.g. makes the plus/minus-buttons work.
 */
export class Add2CartDialogContent {
    static CART_BUTTON_QUERY_SELECTOR = '.eventgallery-opencart'
    cartProcessor: CartProcessor;
    inputFields: HTMLInputElement[] = []
    plusButtons: Element[] = []
    minusButtons: Element[] = []
    cartButton : Element

    constructor(cartProcessor: CartProcessor) {
        this.cartProcessor = cartProcessor;
        this.initialize();
    }

    initialize() {

        this.cartButton = document.querySelector(Add2CartDialogContent.CART_BUTTON_QUERY_SELECTOR)


        let q = document.getElementsByClassName('eventgallery-cartquantity');
        for(let i=0; i<q.length; i++) {
            q[i].addEventListener('change', (e)=>this.updateQuantity(e));
            this.inputFields.push(<HTMLInputElement>q[i])
        }

        let p = document.getElementsByClassName('eventgallery-qtyplus');
        for(let i=0; i<p.length; i++) {
            p[i].addEventListener('click', (e) => this.quantityPlus(e), {capture: true});
            this.plusButtons.push(p[i])
        }

        let m = document.getElementsByClassName('eventgallery-qtyminus');
        for(let i=0; i<m.length; i++) {
            m[i].addEventListener('click', (e) => this.quantityMinus(e), {capture: true});
            this.minusButtons.push(m[i])
        }

        if (document.querySelector('#eg-single-image-type-action-needed')) {
            this.cartProcessor.loadCart();
        }

        this.updateToCartButton()
    }

    updateToCartButton() {
        let sum = 0
        this.inputFields.forEach((field) => {
            sum += parseInt(field.value);
        })

        if (sum > 0 || this.inputFields.length === 0) {
            this.cartButton.disabled = false
        } else {
            this.cartButton.disabled = true
        }
    }

    updateQuantity(e:Event) {
        e.preventDefault();

        let inputFild: HTMLInputElement = e.target as HTMLInputElement;
        let quantity: number = parseInt(inputFild.value);
        if (isNaN(quantity)) {
            quantity = 1;
        }
        let reference = inputFild.getAttribute('data-id');

        this.cartProcessor.add(reference, quantity);
        this.updateToCartButton()
    };

    quantityPlus(e:Event) {
        e.preventDefault();

        let target = e.target as HTMLElement;
        let fieldName: string = target.getAttribute('field');
        let inputField: HTMLInputElement = document.getElementsByName(fieldName)[0] as HTMLInputElement;
        let currentVal: number = parseInt(inputField.value);

        if (!isNaN(currentVal)) {
            let maxOrderQuantity:number = parseInt(inputField.getAttribute('data-maxorderquantity'));
            if (maxOrderQuantity === 0 || currentVal<maxOrderQuantity) {
                inputField.value = String(currentVal + 1);
            }
            let changeEvent = new Event('change');
            inputField.dispatchEvent(changeEvent);
        } else {
            inputField.value = "0";
        }
        this.updateToCartButton()
    };

    quantityMinus(e:Event) {
        e.preventDefault();

        let target = e.target as HTMLElement;
        let fieldName: string = target.getAttribute('field');
        let inputField: HTMLInputElement = document.getElementsByName(fieldName)[0] as HTMLInputElement;
        let currentVal: number = parseInt(inputField.value);

        if (!isNaN(currentVal) && currentVal > 0) {
            inputField.value = String(currentVal - 1);
            let changeEvent = new Event('change');
            inputField.dispatchEvent(changeEvent);
        } else {
            inputField.value = "0";
        }
        this.updateToCartButton()
    };
}