window.Eventgallery = window.Eventgallery || {};

require('./frontend/js/EventgalleryBehavior.js');

require('./frontend/js/photoswipe/PhotoSwipeGallery.js');
require('./frontend/js/photoswipe/PhotoSwipeGallerySlide.js');

require('./frontend/less/eventgallery.less');
require('./frontend/less/font-awesome/font-awesome.less');

require('./frontend/js/minicart/index');
require('./frontend/js/socialbutton/index');
require('./frontend/js/jsgallery/index');

// Required Core Stylesheet
import "@glidejs/glide/src/assets/sass/glide.core.scss";
import "./frontend/sass/glide/glide.theme.scss";

