import {isString, isArray, isNodeList} from "./units";

export default class EventsHandler {
    /**
     * Construct a EventsHandler instance.
     */
    constructor (listeners = {}) {
        this.listeners = listeners;
    }

    /**
     * Adds events listeners to arrows HTML elements.
     *
     * @param  {String|Array} events
     * @param  {NodeList|Element|Window|Document} el
     * @param  {Function} closure
     * @param  {boolean} capture
     */
    on (events, el, closure, capture = false, passive = false) {
        if (isString(events)) {
            events = [events]
        }

        if (!isNodeList(el) && !isArray(el)) {
            el = [el];
        }

        events.forEach((event)=> {
            this.listeners[event] = {'closure': closure, 'options': {'capture': capture, 'passive': passive}};

            for (let i=0; i<el.length;i++) {
                el[i].addEventListener(event, this.listeners[event].closure, this.listeners[event].options)
            }

        });
    }

    /**
     * Removes event listeners from arrows HTML elements.
     *
     * @param  {String|Array} events
     * @param  {NodeList|Element|Window|Document} el
     */
    off (events, el) {
        if (isString(events)) {
            events = [events]
        }

        if (!isNodeList(el) && !isArray(el)) {
            el = [el];
        }

        events.forEach((event) => {
            for (let i=0; i<el.length;i++) {
                let listener = this.listeners[event];
                if (listener === undefined) {
                    continue;
                }
                el[i].removeEventListener(event, listener.closure, listener.options)
            }
        });
    }

    /**
     * Destroy collected listeners.
     *
     */
    destroy () {
        delete this.listeners
    }
}
