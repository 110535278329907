import EventsHandler from "../utils/eventshandler";
import {Events} from "./CartEvents";
import {getParent} from "../../../common/js/Helpers";

/**
 * takes care about the external cart connector buttons on the images:
 * - update the click events
 */

export class CartConnectorButtonHandler {
    eventHandler:EventsHandler = new EventsHandler();


    constructor() {
        this.initializeCartConnectorButtons();
        document.addEventListener(Events.rebindAdd2CartButtons, () => {this.initializeCartConnectorButtons()})
    }

    initializeCartConnectorButtons() {
        let cartConnectorButtons = document.getElementsByClassName('eventgallery-cart-connector');
        Array.prototype.forEach.call(cartConnectorButtons, (btn: HTMLBaseElement)=> {
            this.eventHandler.off('click', btn);
            this.eventHandler.on('click', btn, (e:Event) => {this.openLink(e)});
        });
    }

    openLink(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        let link: HTMLElement = e.target as HTMLElement;

        if (!link.getAttribute('data-href')) {
            link = getParent(link, 'SPAN');
        }

        window.location.href = link.getAttribute('data-href');
    };
}
