const {forEach} = require("../../../common/js/Helpers");
const {JSGallery2} = require("./EventgalleryJSGallery2");


/* start the eventgallery*/
document.addEventListener('DOMContentLoaded', () => {

    let galleryElements = document.querySelectorAll('.ajaxpaging');
    for(let i=0; i<galleryElements.length; i++) {
        let galleryElement = galleryElements[i];

        new JSGallery2(
            galleryElement,
            galleryElement.querySelectorAll('.ajax-thumbnail-container'),
            galleryElement.querySelector('#bigimageContainer'),
            galleryElement.querySelector('#pageContainer'),
            {
                'prevHandle': galleryElement.querySelector('#prev'),
                'nextHandle': galleryElement.querySelector('#next'),
                'countHandle': galleryElement.querySelector('#count'),
                'titleTarget': '#bigImageDescription',
                'showSocialMediaButton': galleryElement.getAttribute('data-showSocialMediaButton')=='true',
                'showCartButton': galleryElement.getAttribute('data-showCartButton')=='true',
                'showCartConnector': galleryElement.getAttribute('data-showCartConnector')=='true',
                'cartConnectorLinkRel': galleryElement.getAttribute('data-cartConnectorLinkRel'),
                'lightboxRel': 'gallery'
            });

    }
});
