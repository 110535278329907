import Imagelist from "./EventgalleryImageList";
import {forEach} from "../../common/js/Helpers";

export default class EventsList extends Imagelist{
    constructor(newOptions) {
        super(newOptions);
    }

    processList() {
        let width = this.width;
        forEach(this.images, (image) => {
            let height = Math.ceil(width * this.options.rowHeightPercentage / 100);
            image.setSize(width, height);
        })

    };
}
